.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
}

.portfolio img {
  width: 100%;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
}